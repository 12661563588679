// stylelint-disable
$spacer: 1rem;

// CUSTOM SPACING
$spacing-0: 0;
$spacing-xxs: $spacer / 2; // 0.5rem
$spacing-xs: $spacer; // 1rem
$spacing-s: $spacer * 1.5; // 1.5rem
$spacing-m: $spacer * 2; // 2rem
$spacing-l: $spacer * 2.5; // 2.5rem
$spacing-xxl: $spacer * 3; // 3rem

// BRANDING COLORS
$color-dark: #111;
$color-gray-dark: #333;
$color-gray-middle-dark: #4d4d4d;
$color-gray: rgba(0, 0, 0, 0.5);
$color-gray-middle: #ccc;
$color-gray-light: rgba(0, 0, 0, 0.1);
$color-gray-lighter: #f5f5f5;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #22bbef;
$red: #e8190e;
$orange: #e64414;
$yellow: #ffcc00;
$green: #95c11f;
$indigo: #6610f2;
$pink: #ea5198;

$colors: ();

$primary: $orange;
$secondary: $gray-600;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$font-size-base: 1.6rem; // Assumes the browser default, typically `16px`
$font-size-xxsm: ($font-size-base * 0.625); // 1rem
$font-size-xsm: ($font-size-base * 0.75); // 1.2rem
$font-size-sm: ($font-size-base * 0.875); // 1.4rem
$font-size-lg: ($font-size-base * 1.125); // 1.8rem
$font-size-xlg: ($font-size-base * 1.25); // 2.0rem
$font-size-xxlg: ($font-size-base * 1.5); // 2.4rem
$font-size-xxxlg: ($font-size-base * 2.25); // 3.6rem

$font-weight-italic: italic;
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

$line-height: 1.8rem;
$line-height-sm: 1.5;
$line-height-base: 1.5;
$line-height-lg: 2;

$zindex-spinner: 2050;
$zindex-side-menu: 1060;
$zindex-modal: 1070;

$border-radius-sm: 0.2rem;
$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-xl: 0.4rem;
$border-radius-xxl: 0.8rem;
$border-radius-xxxl: 1rem;
