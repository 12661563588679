.nav-tabs {
  margin-bottom: $spacing-m;
  border: none;
  box-shadow: 0 0.7rem 0.6rem -0.6rem rgba($black, 0.1);

  .nav-item {
    margin-right: auto;
  }

  .nav-link,
  .nav-link:focus,
  .nav-link:hover {
    padding-left: 1px;
    padding-right: 1px;
    color: $color-gray;
    border: none;
  }

  .nav-link:focus,
  .nav-link:hover {
    color: $primary;
  }

  .nav-link.active {
    color: $black;
    border-bottom: 2px solid $primary;

    &:focus,
    &:hover {
      color: $black;
    }
  }
}
