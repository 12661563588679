$status-color-green: #96c11d;
$status-color-blue: #28baef;
$status-color-orange: #f39200;
$status-color-yellow: #fccb01;
$status-color-grey: #787878;
$status-color-red: #cd5454;

.instrument-status {
  &-running {
    fill: $status-color-green;
  }

  &-idle {
    fill: $status-color-blue;
  }

  &-paused {
    fill: $status-color-orange;
  }

  &-stopped {
    fill: $status-color-blue;
  }

  &-user-interaction-required {
    fill: $status-color-yellow;
  }

  &-error {
    fill: $status-color-orange;
  }

  &-software-not-running {
    fill: $status-color-grey;
  }

  &-not-connected {
    fill: $status-color-grey;
  }
}

.message-type {
  &-error {
    fill: $status-color-red;
  }

  &-warning {
    fill: $status-color-yellow;
  }

  &-comment {
    fill: $status-color-blue;
  }

  &-info {
    fill: #000;
  }

  &-other {
    fill: #000;
  }
}
