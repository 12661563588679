:host {
  .nav-tabs {
    .nav-item {
      &.disabled {
        a {
          &.disabled {
            cursor: default;
          }
        }
      }
    }
  }
}
