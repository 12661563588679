@font-face {
  font-family: 'Yantramanav';
  src: url(~src/assets/fonts/Yantramanav/Yantramanav-Light.ttf) format('truetype');
}

@mixin font-face($font-name, $file-name, $weight: $font-weight-normal, $style: normal) {
  $path: '/assets/fonts/' + quote($font-name) + '/' + $file-name;

  @font-face {
    font-family: quote($font-name);
    // currently we are not using woff2, woff, or svg however commented code is left for future reference
    // src: url($path + '.woff2') format('woff2'), url($path + '.woff') format('woff'),
    //   url($path + '.ttf') format('truetype'), url($path + '.svg##{$font-name}') format('svg');
    src: url($path + '.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face(Roboto, 'Roboto-Regular');
@include font-face(Roboto, 'Roboto-Italic', $font-weight-normal, $font-weight-italic);
@include font-face(Roboto, 'Roboto-Medium', $font-weight-medium);
@include font-face(Roboto, 'Roboto-MediumItalic', $font-weight-medium, $font-weight-italic);
@include font-face(Roboto, 'Roboto-Bold', $font-weight-bold);
@include font-face(Roboto, 'Roboto-BoldItalic', $font-weight-bold, $font-weight-italic);

@include font-face(Yantramanav, 'Yantramanav-Thin', $font-weight-thin);
@include font-face(Yantramanav, 'Yantramanav-Light', $font-weight-light);
@include font-face(Yantramanav, 'Yantramanav-Regular');
@include font-face(Yantramanav, 'Yantramanav-Medium', $font-weight-medium);
@include font-face(Yantramanav, 'Yantramanav-Bold', $font-weight-bold);
