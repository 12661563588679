$spinner-ball-size: 2rem;
$spinner-ball-size-small: 1rem;
$spinner-ball-size-mini: 0.4rem;
$spinner-overlay-background: rgba(255, 255, 255, 0.6);

@mixin spinner-ball-size($size) {
  width: $size;
  height: $size;
  border-radius: $size / 2;
  margin: $size / 4;
}

.spinner {
  &.relative-to-content {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.relative-to-viewport {
    .spinner-overlay {
      position: fixed;
      z-index: $zindex-spinner;
    }
  }

  &-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-spinner;
    background-color: $spinner-overlay-background;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in-out;

    &.fade-in {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &-ball {
    @include spinner-ball-size($spinner-ball-size);

    background-color: $primary;
    animation: pulse 0.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955) both;

    &:nth-child(2) {
      animation-delay: 0.125s;
    }

    &:nth-child(3) {
      animation-delay: 0.25s;
    }
  }

  &-small &-ball {
    @include spinner-ball-size($spinner-ball-size-small);
  }

  &-mini &-ball {
    @include spinner-ball-size($spinner-ball-size-mini);
  }

  &-primary &-ball {
    background-color: $blue;
  }

  &-transparent {
    .spinner-overlay {
      background-color: transparent;
    }
  }
}

@keyframes pulse {
  75% {
    transform: scale(1);
    opacity: 1;
  }

  0% {
    transform: scale(0);
    opacity: 0.3;
  }
}
