body {
  font-family: Yantramanav, sans-serif;
  font-size: $font-size-sm;
  line-height: $line-height;
  font-weight: $font-weight-normal;
  color: $black;
}

%word-wrap {
  /* Mozilla fix */
  word-break: break-all;

  /* Chrome fix */
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
// HEADINGS

%heading {
  margin: 0;
  font-weight: $font-weight-normal;
}

h1,
.h1 {
  @extend %heading;

  padding: 5px 0 0;
  font-size: $font-size-xxxlg;
}

h2,
.h2 {
  @extend %heading;

  font-size: $font-size-xxlg;
}

h3,
.h3 {
  @extend %heading;

  font-size: $font-size-lg;
}

.text-black {
  color: $color-dark;
}

// LINK
a,
a:visited {
  color: $blue;
  text-decoration: none;
}

a:hover {
  color: $primary;
}
